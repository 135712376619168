import React, { useState } from 'react';
import './cryptocalculator.css';

const CryptocurrencyCalculator = () => {
  const [allTimeHigh, setAllTimeHigh] = useState('');
  const [allTimeLow, setAllTimeLow] = useState('');
  const [currentPrice, setCurrentPrice] = useState('');
  const [circulatingSupply, setCirculatingSupply] = useState('');
  const [tradingVolume, setTradingVolume] = useState('');

  const calculateRatios = () => {
    // const allTimeHighLowRatio = allTimeLow !== '' ? allTimeHigh / allTimeLow * 100 : 0;
    // const allTimeHighCurrentRatio = currentPrice !== '' ? allTimeHigh / currentPrice : 0;
    // const firstResult = allTimeHighCurrentRatio !== 0 ? allTimeHighLowRatio / allTimeHighCurrentRatio / 100 : 0;
    const firstResult = tradingVolume !== '' ? allTimeHigh - allTimeLow * tradingVolume / circulatingSupply : 0;
    // const tradingVolumeRatio = tradingVolume !== '' ? circulatingSupply / tradingVolume  : 0;
    const tradingVolumeRatio = tradingVolume !== '' ? tradingVolume * currentPrice / circulatingSupply : 0;
    const secondResult = tradingVolumeRatio !== '' ?  firstResult + tradingVolumeRatio: 0;

    // const circulatingSupplyRatio = circulatingSupply !== '' ? currentPrice / circulatingSupply : 0;
    // const tradingVolumeRatio = circulatingSupply !== '' ? tradingVolume / circulatingSupply : 0;

    // const secondResult = firstResult + circulatingSupplyRatio + tradingVolumeRatio;

    return {
      secondResult,
    };
  };

  const ratios = calculateRatios();

  return (
    <div className='value_cal-div'>
      <h1>Value Calculator</h1>
      <sub className='testing_phase'>Alpha (Early stage)</sub>
      <input className='ath_input' type="number" value={allTimeHigh} onChange={(e) => setAllTimeHigh(e.target.value)} placeholder="Input All Time High:" />
      <input type="number" value={allTimeLow} onChange={(e) => setAllTimeLow(e.target.value)} placeholder="All Time Low" />
      <input type="number" value={currentPrice} onChange={(e) => setCurrentPrice(e.target.value)} placeholder="Current Price" />
      <input
        type="number"
        value={circulatingSupply}
        onChange={(e) => setCirculatingSupply(e.target.value)}
        placeholder="Circulating Supply"
      />
      <input type="number" value={tradingVolume} onChange={(e) => setTradingVolume(e.target.value)} placeholder="Trading Volume"/>
      <div className='output'>
        {/* <h2>Ratios:</h2> */}
        {/* {ratios.firstResult !== 0 && (
          <p className='growth_p'>Possible Growth: {ratios.firstResult}%</p>
        )} */}
        {ratios.secondResult !== 0 && (
          <p className='price_p'>The Value is <span className='output_span'> ${ratios.secondResult}</span> based on Supply, <br/> trading Volume and other factors.</p>
        )}
      </div>
    </div>
  );
};

export default CryptocurrencyCalculator;
