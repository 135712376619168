import { BrowserRouter as Router } from "react-router-dom";
import Layouts from "./layout/Layout";
import './App.css';

function App() {
  return (
    <div className="App">
    <Router>
      <Layouts/>
    </Router>
    </div>
  );
}

export default App;
