import React from 'react'
import Home from '../components/Home/Home'
import { Routes, Route } from 'react-router-dom'
import Navbar from '../components/navbar/Navbar'
import Top from '../components/top/Top'
import './layout.css'
import Footer from '../components/footer/Footer'
import Premium from '../pages/premium/Premium'
import ValueCalculator from '../pages/valuecalculator/CryptocurrencyCalculator';
import FormValidation from '../FormComponent'

const Layouts = () => {
  return (
    <div className="layout-container">
        <Top/>
      <Navbar />
      <div className="layout">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/premium' element={<Premium/>}/>
          <Route path='/value-calculator' element={<ValueCalculator/>}/>
          <Route path='/subscription' element={<FormValidation/>}/>
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default Layouts
