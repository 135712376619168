import React from 'react';
import CoinGeckoWidget from '../widget/CoinGeckoMarqueeWidget';
import CountdownTimer from '../countdown/CountdownTimer';
import './top.css';
function Top() {
  return (
    <div className='top_main-container'>
                <CoinGeckoWidget />
            <div className='top_assets'>
                <p>Global Tradable Coins: <span>2m+</span></p>
                <p>Total Exchanges: <span>695</span></p>
                <CountdownTimer/>
            </div>
    </div>
  )
}

export default Top