import React from 'react';
import './header.css';

function Header() {
  return (
    <div className='header_container'>
                    <h1>Selected High valued Coins and Tokens in the Market</h1>
        <div className="contents_div">
            <p>Discover the hidden gems in the market – coins and tokens worth considering for your investment journey. Presented in a casually curated list, their order holds no significance, yet be sure to check their trust levels.</p>
            <p>Remember that there is no institution that is too big to fail. Cryptocurrency are volatile and Risky in trading, but we take our time to sort the great ones in the market that are still good for trading.</p>
        </div>
    </div>
  )
}

export default Header