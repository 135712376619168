import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import Logo from '../../assets/htc-white.png';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setShowMenu(false); // Close the menu on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    if (isMobile) {
      setShowMenu(!showMenu);
    }
  };

  const closeMobileMenu = () => {
    setShowMenu(false);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to={'/'} className="links">
          <img src={Logo} alt="" />
        </Link>
      </div>

      {isMobile ? (
        <div className="hamburger" onClick={handleMenuClick}>
          <div className={`line ${showMenu ? 'open' : ''}`} />
          <div className={`line ${showMenu ? 'open' : ''}`} />
          <div className={`line ${showMenu ? 'open' : ''}`} />
        </div>
      ) : (
        <ul className="nav-links">
          <li>
            <Link to={'/value-calculator'} className="links">
              Value Calculator
            </Link>
          </li>
          <li className='tweet_link'> <a href="https://twitter.com/hightradecoin" target='_blank' rel="noreferrer" >Tweets X</a></li>
          <li>
            <Link to={'/premium'} className="links">
              Premium Members
            </Link>
          </li>
        </ul>
      )}

      {isMobile && (
        <div className={`mobile-menu ${showMenu ? 'show' : ''}`}>
          <button className="close-button" onClick={closeMobileMenu}>
            X
          </button>
          <ul>
            <li>
              <Link to={'/value-calculator'} className="links" onClick={handleMenuClick}>
                Value Calculator
              </Link>
            </li>
            <li className='tweet_link'> <a href="https://twitter.com/hightradecoin" target='_blank' rel="noreferrer" >Tweets X</a></li>
            <li>
              <Link to={'/premium'} className="links" onClick={handleMenuClick}>
                Premium Members
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
