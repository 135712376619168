import { db } from "../src/firebase";
import { collection, addDoc } from "firebase/firestore";

const dataCollectionRef = collection(db, "projects");

class UserDataService {
    addUser = (newUser) => {
        return addDoc(dataCollectionRef, newUser);
    };
}

// eslint-disable-next-line
export default new UserDataService();
