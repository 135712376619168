import React from 'react';
import { Link } from 'react-router-dom';
import './premium.css';

function Premium() {
  return (
    <div className='premium_container'>
        <h1>HighTrade Premium</h1>
        <p>Receive timely and well-researched trade recommendations based on market analysis, empowering users to make informed investment decisions.😎</p>
        <p>Educational Resources: Stay informed with educational resources, including articles and tutorials, to enhance your understanding of cryptocurrency markets and trading strategies.</p>
        <div className='premium_box'>
            <div className='box_one'>
                <h2>Liege</h2>
               <h4>2024 Plan</h4>
                <p className='box_p'> Embark on your crypto journey with the Liege, a gateway to exclusive guides, curated token selections, and expert risk management strategies. Gain unparalleled insights into the cryptocurrency market, ensuring you make informed decisions and seize lucrative opportunities.</p>
                <h1>$25</h1>
              <Link className='linkks' to={'/subscription'}>  <button className='button'>Subscribe</button></Link>
                <p className='value_head'>Components</p>
                <ul className='list_stuff'>
                    <li>2 - 4 Coin</li>
                    <li>2 Margin Trade</li>
                    <li>Airdrops</li>
                    <li>Pro Guide For Risk Management</li>
                </ul>
            </div>
            
            <div className='box_two'>
                <h1>Colossal</h1>
                <h4>2024 Plan</h4>
                <p className='box_p'>Unlock the pinnacle of crypto success with the Colossal, your golden ticket to staying steps ahead in the market and accessing the absolute best. Immerse yourself in a world of elite benefits, featuring professional guides and unparalleled investment opportunities.</p>
                <h1>$50</h1>
                <Link className='linkks' to={'/subscription'}>  <button className='button'>Subscribe</button></Link>
                <p className='value_head'>Components</p>
                <ul className='list_stuff'>
                    <li>4 - 8 Coins</li>
                    <li>2 - 5 Margin Trades</li>
                    <li>Special Airdrops</li>
                    <li>Investment and Trading strategies</li>
                    <li>Pro Guide For Risk Management</li>
                </ul>
            </div>
        </div>
    </div>  
  )
}

export default Premium