import React, { useState, useEffect } from 'react';
import './countdown.css';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const targetDate = new Date('April 27, 2024').getTime();
    const currentDate = new Date().getTime();
    const difference = targetDate - currentDate;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='countdown_div'>
      <p> Bitcoin Halving Countdown: <span>{`${timeLeft.days} days, ${timeLeft.hours} hrs, ${timeLeft.minutes} min, ${timeLeft.seconds} sec`}</span></p>
    </div>
  );
};

export default CountdownTimer;
