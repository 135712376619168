import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

const CoinGeckoMarqueeWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    const widget = widgetRef.current;

    if (widget) {
      // Set the coin-ids
      widget.setAttribute('coin-ids', 'bitcoin,ethereum,ripple,solana,avalanche-2,near,uniswap,pancakeswap-token,cardano,cosmos,tia,vethor-token,sei-network,flow,neo,osmosis');

      // Set the currency
      widget.setAttribute('currency', 'usd');

      // Set the background color
      widget.setAttribute('background-color', '#000000');

      // Set the font color
      widget.setAttribute('font-color', '#ffffff');

      // Set the locale
      widget.setAttribute('locale', 'en');
    }
  }, []);

  return (
    <div>
      <Helmet>
        <script src="https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js" />
      </Helmet>
      <coingecko-coin-price-marquee-widget ref={widgetRef} />
    </div>
  );
};

export default CoinGeckoMarqueeWidget;
