// Footer.js

import React from 'react';
import './footer.css'; // Create this file for additional styling if needed
import { Link } from 'react-router-dom';
import Logo from '../../assets/htc2.png'

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-left">
        <img src={Logo} alt="Logo" className="footer-logo" />
      </div>
      <div className="footer-right">
        <ul className="footer-menu">
        <li> <a href="https://twitter.com/hightradecoin" target='_blank' rel="noreferrer" >Tweets X</a></li>
          <Link className='foot_link' to={'/value-calculator'}>Value Calculator</Link>
          <Link className='foot_link' to={'/premium'}>Premium Membership</Link>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
