import React, { useState } from 'react';
import './copytoclipboard.css';

const CopyToClipboard = () => {
  const [copiedBTC, setCopiedBTC] = useState(false);
  const [copiedETH, setCopiedETH] = useState(false);
  const [copiedBNB, setCopiedBNB] = useState(false);
  const [copiedUSDT, setCopiedUSDT] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleCopy = async (address, setCopiedState) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopiedState(true);
    } catch (error) {
      console.error('Unable to copy to clipboard', error);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div>
      <button onClick={handleDropdownToggle}>Show Wallet Addresses</button>
      {showDropdown && (
        <div className="wallet-dropdown">
          <p>Bitcoin (BTC) Address:</p>
          <div>
            <span>bc1qntzd6thjhp2h93ewdspgya2z3lgjeanpz3fzwm</span>
            <button onClick={() => handleCopy('bc1qntzd6thjhp2h93ewdspgya2z3lgjeanpz3fzwm', setCopiedBTC)}>
              {copiedBTC ? 'Copied!' : 'Copy BTC Address'}
            </button>
          </div>

          <p>Ethereum (ETH) Address:</p>
          <div>
            <span>0x652Fcc2E0CBaeE4FdEC927Dd963Ea5609d2486AE</span>
            <button onClick={() => handleCopy('0x652Fcc2E0CBaeE4FdEC927Dd963Ea5609d2486AE', setCopiedETH)}>
              {copiedETH ? 'Copied!' : 'Copy ETH Address'}
            </button>
          </div>

          <p>Binance Smart Chain (BNB) Address:</p>
          <div>
            <span>0x652Fcc2E0CBaeE4FdEC927Dd963Ea5609d2486AE</span>
            <button onClick={() => handleCopy('0x652Fcc2E0CBaeE4FdEC927Dd963Ea5609d2486AE', setCopiedBNB)}>
              {copiedBNB ? 'Copied!' : 'Copy BNB Address'}
            </button>
          </div>

          <p>USD Tether TRC20 (USDT) Address:</p>
          <div>
            <span>TSSEyM91RUwNnJsd3LFM1yqZEPBz5y49b5</span>
            <button onClick={() => handleCopy('TSSEyM91RUwNnJsd3LFM1yqZEPBz5y49b5', setCopiedUSDT)}>
              {copiedUSDT ? 'Copied!' : 'Copy USDT Address'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CopyToClipboard;
