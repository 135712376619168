import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAqNmUHFXNNe9z1rJ-k6j4NgB8OIxamX5Y",
  authDomain: "hightrustcoin.firebaseapp.com",
  projectId: "hightrustcoin",
  storageBucket: "hightrustcoin.appspot.com",
  messagingSenderId: "726970887416",
  appId: "1:726970887416:web:45e5932171d2f9de119f0d",
  measurementId: "G-KWER599DLD"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
