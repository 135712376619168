import React from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import './hightrust.css';



function Hightrust() {
  return (
    <div className='table_container coin-list-container'>
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          {/* <th>Price</th> */}
          <th><span>BUY</span></th>
          <th>
              Trust Score{' '}
            </th>          
            <th>Increase in a long time.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/solana-sol-logo.png?v=029" alt="" /> Solana <span>SOL</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/SOL_USDT?" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={84}  bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={88}  bgColor='#28f0d1' labelColor='#000'/></td>
        </tr>
        <tr>
          <td>2</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/xrp-xrp-logo.png?v=029" alt="" /> Ripple <span>XRP</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/XRP_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>3</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/cardano-ada-logo.png?v=029" alt="" /> Cardano <span>ADA</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/ADA_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={86} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>4</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/avalanche-avax-logo.png?v=029" alt="" /> Avalanche <span>AVAX</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/AVAX_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={81} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={85} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>5</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/polygon-matic-logo.png?v=029" alt="" /> Polygon <span>MATIC</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/MATIC_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>6</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/chainlink-link-logo.png?v=029" alt="" /> Chainlink <span>LINK</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/LINK_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={84} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>7</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/polkadot-new-dot-logo.png?v=029" alt="" /> Polkadot <span>DOT</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/DOT_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>8</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/internet-computer-icp-logo.png?v=029" alt="" /> Internet Computer <span>ICP</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/ICP_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span> </a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={87} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>9</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/cosmos-atom-logo.png?v=029" alt="" /> Cosmos <span>ATOM</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/ATOM_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={84} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>10</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/injective-inj-logo.png?v=029" alt="" /> Injective <span>INJ</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/INJ_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>11</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=029" alt="" /> Optimism <span>OP</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/OP_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={78} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>12</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/near-protocol-near-logo.png?v=029" alt="" /> Near Protocol <span>NEAR</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/NEAR_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>13</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/aptos-apt-logo.png?v=029" alt="" /> Aptos <span>APT</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/APT_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={81} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>14</td>
          <td className='crypto_logo'> <img src="https://lw3-teams-logos.s3.us-east-2.amazonaws.com/Celestia-team-logo" alt="" /> Celestia <span>TIA</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/TIA_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>15</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/filecoin-fil-logo.png?v=029" alt="" /> Filecoin <span>FIL</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/FIL_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={85} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>16</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=029" alt="" /> Arbitrum <span>ARB</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/ARB_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={85} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>17</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/bnb-bnb-logo.png?v=029" alt="" /> Binance <span>BNB</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/BNB_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={88} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={86} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>18</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/sui-sui-logo.png?v=029" alt="" /> Sui <span>SUI</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/SUI_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>19</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/fantom-ftm-logo.png?v=029" alt="" /> Fantom <span>FTM</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/FTM_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={72} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>20</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/shiba-inu-shib-logo.png?v=029" alt="" /> Shiba Inu <span>SHIB</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/SHIB_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>21</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/dogecoin-doge-logo.png?v=029" alt="" /> Dogecoin <span>DOGE</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/DOGE_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={84} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={85} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>22</td>
          <td className='crypto_logo'> <img src="https://s2.coinmarketcap.com/static/img/coins/200x200/28301.png" alt="" /> Meme</td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/MEME_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={75} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={85} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>23</td>
          <td className='crypto_logo'> <img src="https://s2.coinmarketcap.com/static/img/coins/200x200/23095.png" alt="" /> Bonk</td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/BONK_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>24</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/vechain-vet-logo.png?v=029" alt="" /> VeChain <span>VET</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/VET_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={76} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>25</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/thorchain-rune-logo.png?v=029" alt="" /> Thorchain <span>RUNE</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/RUNE_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={84} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>26</td>
          <td className='crypto_logo'> <img src="https://s2.coinmarketcap.com/static/img/coins/200x200/25028.png" alt="" /> Ordi</td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/ORDI_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={78} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>27</td>
          <td className='crypto_logo'> <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/23149.png" alt="" /> Sei <span>SEI</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/SEI_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={82} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={81} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>28</td>
          <td className='crypto_logo'> <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/28683.png" alt="" /> 1000sat</td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/1000SATS_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={72} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={79} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>29</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/pancakeswap-cake-logo.png?v=029" alt="" /> PancakeSwap <span>CAKE</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/CAKE_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={83} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
        <tr>
          <td>30</td>
          <td className='crypto_logo'> <img src="https://cryptologos.cc/logos/axie-infinity-axs-logo.png?v=029" alt="" /> Axie Infinity <span>AXS</span></td>
          {/* <td>$60,000</td> */}
          <td className='usdt'><a href="https://www.binance.com/en/trade/AXS_USDT?ref=40896146" target='_blank' rel="noreferrer"><span>BUY</span></a></td>
          <td><ProgressBar completed={80} bgColor='#28f0d1' labelColor='#000' /></td>
          <td><ProgressBar completed={84} bgColor='#28f0d1' labelColor='#000' /></td>
        </tr>
      </tbody>
    </table>
    <i className='disclaimer'>"Disclaimer: HighTradeCoin does not advocate for any specific project. We check the best and recommend for you to benefit."</i>
    </div>
  )
}

export default Hightrust