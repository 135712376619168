import React from 'react';
import Header from '../header/Header';
import Hightrust from '../Hightrust/Hightrust';


function Home() {
  return (
    <div>
        <Header/>
        <Hightrust/>
    </div>
  )
}

export default Home