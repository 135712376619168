import React, { useState } from 'react';
import UserDataService from './firebase.service';
import CopyToClipboard from './components/copytoclip/CopyToClipboard';
import './form.css';

const ProjectsData = () => {
  const [tran, setTran] = useState('');
  const [email, setEmail] = useState('');
  const [conemail, setConemail] = useState('');
  const [notification, setNotification] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tran || !email || !conemail) {
      setNotification('Please fill in all fields.');
      return;
    }

    if (email !== conemail) {
      setNotification('Emails do not match.');
      return;
    }

    const newProject = {
      tran: tran,
      email: email,
      conemail: conemail,
    };

    try {
      await UserDataService.addUser(newProject);
      setNotification('Submission successful! You will receive a mail within 24hrs');
    } catch (err) {
      setNotification(`Error: ${err.message}`);
    }

    setTran('');
    setEmail('');
    setConemail('');
  };

  return (
    <div className="bul3_form-container">
      <div className="descrip">
        <p>
          Send into any of the addresses below and submit your <br/> wallet deposit or transaction id. <br />{' '}
        </p>
       <CopyToClipboard/>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="Tran"
          placeholder="Wallet Address or Transaction ID"
          onChange={(e) => setTran(e.target.value)}
          value={tran}
        />
        <input
          type="email"
          name="Email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="email"
          name="ConfirmEmail"
          placeholder="Confirm Email"
          onChange={(e) => setConemail(e.target.value)}
          value={conemail}
        />
        <input
          type="submit"
          value="Submit"
          className="submit_button"
        />
      </form>
      {notification && (
        <div className="notification">
          {notification}
        </div>
      )}
    </div>
  );
};

export default ProjectsData;
